











import {Component, Prop, Vue} from "vue-property-decorator";
import UserView from "@/views/UserView.vue";
import User from "@/models/User";
import Authority, {AuthorityName} from "@/models/Authority";
import AuthorityService from "@/services/AuthorityService";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";

@Component
export default class AuthorityTab extends Vue {
    @Prop() readonly userView!: UserView
    @Prop() readonly userId!: number
    @Prop() readonly user!: User
    authorities: Authority[] = []
    loading: boolean = false

    test() {
      console.log(this.authorities)
    }

    created() {
        AuthorityService.getAuthoritiesByUserId(this, this.authorities, this.userId)
    }

    updateAuthority(authority: Authority) {
        if (authority.enabled) {
            AuthorityService.patchAuthorityUnrelateUser(this, authority, this.userId);
        } else {
            AuthorityService.patchAuthorityRelateUser(this, authority, this.userId);
        }
    }

    isDisabled(authority: Authority): boolean {
        let myAuthorities = getModule(SessionModule).session.authorities
        switch (authority.name) {
            case AuthorityName.SUPER_ADMIN: return true
            case AuthorityName.ADMIN: return !myAuthorities.some(a => a.name == AuthorityName.SUPER_ADMIN)
            case AuthorityName.MANAGER: return !myAuthorities.some(a => {
                return a.name == AuthorityName.SUPER_ADMIN
                    || a.name == AuthorityName.ADMIN
            })
            case AuthorityName.SWAGGER: case AuthorityName.USER: return !myAuthorities.some(a => {
                return a.name == AuthorityName.SUPER_ADMIN
                || a.name == AuthorityName.ADMIN
            })
        }
        return false
    }
}
