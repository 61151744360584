









































import {Component, Prop, Vue} from "vue-property-decorator";
import UserView from "@/views/UserView.vue";
import OrderService from "@/services/OrderService";
import Order, {OrderSelect, OrderType} from "@/models/Order";
import SessionModule from "@/store/SessionModule";
import {getModule} from "vuex-module-decorators";
import Options from "@/models/vue/Options";
import LangModule from "@/store/LangModule";

@Component
export default class OrdersTab extends Vue {
  @Prop() readonly userView!: UserView
  @Prop() readonly userId!: number
  loading: boolean = false
  orders: Order[] = []
    page: number = 1
    pageCount: number = 0
    itemsPerPage: number = 10
    totalItems: number = 0
    search: string = ""
  headers = [
      { text: "Fecha", value: "createdAt", width: "200px" },
      { text: "Estado", value: "status", width: "200px" },
      { text: "Acciones", value: "actions", width: "200px" },
  ]
    orderTypeFinish: OrderType = OrderType.FINISH
    orderTypeSend: OrderType = OrderType.SEND
    orderTypeDraft: OrderType = OrderType.DRAFT
    orderTypes: OrderSelect[] = [
        {name: "En Espera", value: OrderType.WAITING},
        {name: "Enviado", value: OrderType.SEND},
        {name: "Finalizado", value: OrderType.FINISH},
        {name: "Borrador", value: OrderType.DRAFT},
    ]

  lang: any = getModule(LangModule).lang

  options: Options = new Options()

    isDisableEdit(item: Order): boolean {
        return (item.orderType === this.orderTypeFinish) || (item.orderType === this.orderTypeSend)
    }
    isDisableView(item: Order): boolean {
        return (item.orderType === this.orderTypeDraft)
    }

  created() {
      OrderService.getMyOrdersByUser(
          this, this.orders, this.page - 1, this.itemsPerPage,
          this.userId, this.search, null, undefined, null, null
      )
  }

    rowClick(order: Order) {
        this.$router.push({path: "/order/" + order.id})
    }

}
