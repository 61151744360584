
































































































import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import User from "@/models/User";
import {getModule} from "vuex-module-decorators";
import DialogModule from "@/store/DialogModule";
import Dialog from "@/models/vue/Dialog";
import UserService from "@/services/UserService";
import UserTab from "@/components/tabs/UserTab.vue";
import AuthorityTab from "@/components/tabs/AuthorityTab.vue";
import RouterTool from "@/services/tool/RouterTool";
import OrdersTab from "@/components/tabs/OrdersTab.vue";
import PanelScreenTab from "@/components/tabs/PanelScreenTab.vue";
import SessionModule from "@/store/SessionModule";
import LangModule from "@/store/LangModule";
import BannerService from "@/services/BannerService";

@Component({components: { AuthorityTab, UserTab, OrdersTab, PanelScreenTab }})
export default class UserView extends Vue {
	lang: any = getModule(LangModule).lang
	@Ref() readonly form!: HTMLFormElement
	tabs: string[] = ["/restricted-areas", "/orders"]
	tab: number = 0
	loading: boolean = false
	user: User = new User()
	changePasswordDialog: boolean = false
	password: string = ""
	newPassword: string = ""
	repeatPassword: string = ""
	showPassword: boolean = false
	showNewPassword: boolean = false
	newPasswordRules = [
		(v: string) => v.length > 0 ? true : this.lang.newPasswordRequired,
		(v: string) => v.length >= 4 ? true : this.lang.password4CharactersRequired
	]
	sessionModule: SessionModule = getModule(SessionModule)
	isSuperAdmin = this.sessionModule.session.authorities.map(e => e.title == "super admin")[0]

	deactivateUser() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.userDeactivateQuestion,
			 () => UserService.activateUser(this, this.user.id!, false)
		))
	}

	activateUser() {
		getModule(DialogModule).showDialog(new Dialog(
			 this.lang.warning, this.lang.userActivateQuestion,
			 () => UserService.activateUser(this, this.user.id!, true)
		))
	}

	created() {
		this.tab = RouterTool.configTabs(this, this.tabs)
		this.refresh()
	}

	refresh() {
		UserService.getUser(this, Number(this.$route.params.userId))
	}

	@Watch("tab")
	watchTab() {
		RouterTool.watchTabs(this, "/users/" + Number(this.$route.params.userId), this.tab, this.tabs)
	}

	changePassword() {
		if (this.form.validate()) {
			getModule(DialogModule).showDialog(new Dialog(
				 this.lang.warning, this.lang.passwordUpdateQuestion,
				 () => UserService.patchChangePassword(this, this.password, this.newPassword, Number(this.$route.params.userId))
			))
		}
	}

	openChangePasswordDialog() {
		if (this.form) this.form.resetValidation()
		this.changePasswordDialog = true
		this.password = ""
		this.newPassword = ""
		this.repeatPassword = ""
		this.showPassword = false
		this.showNewPassword = false
	}

}
