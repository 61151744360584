import User from "@/models/User";
import ConstantTool from "@/services/tool/ConstantTool";
import {getModule} from "vuex-module-decorators";
import SessionModule from "@/store/SessionModule";
import {Vue} from "vue-property-decorator";
import JsonTool from "@/services/tool/JsonTool";
import SnackbarModule from "@/store/SnackbarModule";
import {AuthorityName} from "@/models/Authority";
import axios from "axios";
import Response from "@/models/responses/Response";

export default class UserService {

    static changePasswordWasNotified(component: Vue) {
        component.axios.patch(ConstantTool.BASE_URL + "/api/users/@me/change-password/was-notified", null, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
    }

    static postUserCommercial(component: Vue, email: string, password: string, name: string, commercialName: string) {
        // @ts-ignore
        component.loading = true

        let formData = new FormData()
        formData.set("email", email)
        formData.set("password", password)
        formData.set("name", name)
        formData.set("commercialName", commercialName)

        component.axios.post(ConstantTool.BASE_URL + "/api/users/register/commercial",
            formData, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let item: User = JsonTool.jsonConvert.deserializeObject(response.data, User)
                component.$router.push({path: "/commercials/" + item.id})
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo crear el usuario"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static async postUserAdmin(email: string, password: string, name: string): Promise<Response<User>> {
        try {
            let formData = new FormData()
            formData.set("email", email)
            formData.set("password", password)
            formData.set("name", name)

            const response = await axios.post(ConstantTool.BASE_URL + "/api/users/register/admin", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const user = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({result: user})
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo crear el usuario")
            return Promise.reject(e)
        }
    }

    static async postManagerUser(component: Vue, email: string, password: string, name: string) {
        // @ts-ignore
        component.loading = true

        let formData = new FormData()
        formData.set("email", email)
        formData.set("password", password)
        formData.set("name", name)

        try {
            let response = await component.axios.post(ConstantTool.BASE_URL + "/api/users/register/manager", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo crear el usuario")
        } finally {
            // @ts-ignore<
            component.loading = false
        }
    }

    static async postMarketingManagerUser(component: Vue, email: string, password: string, name: string) {

        try {
            let formData = new FormData()
            formData.set("email", email)
            formData.set("password", password)
            formData.set("name", name)

            let response = await component.axios.post(ConstantTool.BASE_URL + "/api/users/register/marketing-manager", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            getModule(SnackbarModule).makeToast("Usuario creado exitosamente.")

            const user = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({result: user})
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo crear el usuario.")
            return Promise.reject(e)
        }
    }

    static async postCustomRelationshipManagerUser(component: Vue, email: string, password: string, name: string) {
        // @ts-ignore
        component.loading = true

        let formData = new FormData()
        formData.set("email", email)
        formData.set("password", password)
        formData.set("name", name)

        try {
            let response = await component.axios.post(ConstantTool.BASE_URL + "/api/users/register/custom-relationship-manager", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
        } catch (e) {
            getModule(SnackbarModule).makeToast("No se pudo crear el usuario")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static getUsers(component: Vue, users: User[], page: number, size: number, role: AuthorityName | null, search: string, active: boolean = true) {
        (<any>component).loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/users", {
            params: {page, size, role, active, search},
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let list = JsonTool.jsonConvert.deserializeArray(response.data, User)
                users.splice(0, users.length)
                list.forEach(p => users.push(p))
                // @ts-ignore
                component.totalItems = Number(response.headers["x-total-count"])
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se han podido obtener los usuarios"))
            .finally( () => (<any>component).loading = false)
    }

    static getUser(component: Vue, userId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/users/" + userId, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, User)
                // @ts-ignore
                component.user = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener el usuario"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getMyCommercial(component: Vue, userId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/@me/commercials/"+ userId +"/users/", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, User)
                // @ts-ignore
                component.commercial = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener el comercial"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static getMyDistributor(component: Vue, userId: number) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/@me/distributors/"+ userId +"/users/", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, User)
                // @ts-ignore
                component.commercial = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener el distribuidor"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static async deleteCommercial(component: Vue, userId: number) {
        try {
            await component.axios.delete(ConstantTool.BASE_URL + "/api/@me/commercial/" + userId + "/users/", {
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            getModule(SnackbarModule).makeToast("usuario comercial eliminado con exito");
            component.$router.push("/commercials")
        } catch (err) {
            console.log(err)
            getModule(SnackbarModule).makeToast("Error al eliminar el usuario comercial")
        }
    }

    static async getMyUser(): Promise<Response<User>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/users/@me", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const user = JsonTool.jsonConvert.deserializeObject(response.data, User)

            return Promise.resolve({result: user})
        } catch (e) {
            getModule(SnackbarModule).makeToast("Ha ocurrido un error buscando al usuario.")
            return Promise.reject(e)
        }
    }

    static getMyUsear(component: Vue) {
        // @ts-ignore
        component.loading = true
        component.axios.get(ConstantTool.BASE_URL + "/api/users/@me", {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
                let entity = JsonTool.jsonConvert.deserializeObject(response.data, User)
                // @ts-ignore
                component.user = entity
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo obtener el usuario"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static async patchCommercialUser(id: number, user: User): Promise<Response<User>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/@me/users/commercial/${id}/update`, user, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const commercialUser = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercialUser })
        } catch (e) {
            return Promise.reject(e)
        }
    }

    static patchUser(component: Vue, user: User) {
        // @ts-ignore
        component.loading = true
        component.axios.patch(ConstantTool.BASE_URL + "/api/@me/users/" + user.id + "/update",
            user, {
            headers: {Authorization: getModule(SessionModule).session.token}
        })
            .then(response => {
            })
            .catch(() => getModule(SnackbarModule).makeToast("No se pudo actualizar el usuario"))
            // @ts-ignore
            .finally(() => component.loading = false)
    }

    static patchChangePassword(component: Vue, password: string, newPassword: string, userId: number) {
        let formData: FormData = new FormData()
        formData.set("password", password)
        formData.set("newPassword", newPassword)
        component.axios.patch(ConstantTool.BASE_URL + "/api/users/" + userId + "/change-password",
            formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                getModule(SnackbarModule).makeToast("Contraseña cambiada con exito")
                // @ts-ignore
                component.changePasswordDialog = false
            })
            .catch(error => getModule(SnackbarModule).makeToast("No se pudo cambiar la contraseña"))
    }

    static patchResetPassword(component: Vue, userId: number) {
        component.axios.patch(ConstantTool.BASE_URL + "/api/users/" + userId + "/reset-password",
            null, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            .then(response => {
                getModule(SnackbarModule).makeToast("La nueva contraseña ha sido enviada a su email")
                // @ts-ignore
                component.changePasswordDialog = false
            })
            .catch(error => getModule(SnackbarModule).makeToast("No se pudo generar la nueva contraseña"))
    }

    static async getMyDistributors(component: Vue, users: User[], page: number, size: number) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/distributors/users", {
                params: {page, size},
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            let list = JsonTool.jsonConvert.deserializeArray(response.data, User);
            users.splice(0, users.length);
            list.forEach(p => users.push(p));
            // @ts-ignore
            component.loading = false;
        } catch (err) {
            // @ts-ignore
            component.loading = false
            console.log(err)
            getModule(SnackbarModule).makeToast("No se han podido obtener los clientes")
        }
    }

    static async getCommercials(component: Vue, commercials: User[], page: number, size: number, all: boolean = false, active: boolean = true) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/commercials/users", {
                params: {page, size, active, all},
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            commercials.splice(0, commercials.length)
            JsonTool.jsonConvert.deserializeArray(response.data, User).forEach(p => commercials.push(p))
        } catch (e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los comerciales")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getMyCommercials2(page: number, size: number, all: boolean = false, active: boolean = true): Promise<Response<User[]>> {
        try {
            const response = await axios.get(ConstantTool.BASE_URL + "/api/@me/commercials/users", {
                headers: {Authorization: getModule(SessionModule).session.token},
                params: { page, size, active, all },
            })
            let commercials = JsonTool.jsonConvert.deserializeArray(response.data, User)
            const xTotalCount = Number(response.headers["x-total-count"])
            return Promise.resolve({ result: commercials, xTotalCount })

        } catch (e) { return Promise.reject(e) }
    }

    static async getMyCommercials(component: Vue, users: User[], page: number, size: number, all: boolean = false, active: boolean = true) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/commercials/users", {
                params: { page, size, active, all },
                headers: {Authorization: getModule(SessionModule).session.token}
            });
            let list = JsonTool.jsonConvert.deserializeArray(response.data, User)
            users.splice(0, users.length)
            list.forEach(p => users.push(p))
            // @ts-ignore
            component.totalItems = Number(response.headers["x-total-count"])
        } catch (err) {
            console.log(err)
            getModule(SnackbarModule).makeToast("No se han podido obtener los comerciales")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async getDistributorsByCompanyId(component: Vue, companyId: number, users: User[]) {
        // @ts-ignore
        component.loading = true

        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/company/" + companyId + "/distributors/users", {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            let list = JsonTool.jsonConvert.deserializeArray(response.data, User)
            users.splice(0, users.length);
            list.forEach(p => users.push(p));
            // @ts-ignore
            component.loading = false;
        } catch (err) {
            // @ts-ignore
            component.loading = false
            console.log(err)
            getModule(SnackbarModule).makeToast("No se han podido obtener los distribuidores")
        }
    }

    static async activateUser(component: Vue, id: number, enable: boolean) {
        let formData = new FormData()
        formData.set("active", enable ? "true" : "false")
        try {
            await component.axios.patch(ConstantTool.BASE_URL + "/api/users/"+ id +"/activate", formData, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            // @ts-ignore
            component.refresh()
            getModule(SnackbarModule).makeToast("El estado del usuario se ha actualizado con exito.")
        } catch (err) {
            console.log(err)
            getModule(SnackbarModule).makeToast("No se han cambiar el estado del usuario")
        } finally {
            // @ts-ignore
            component.loading = false;
        }
    }

    static async activateMyCommercialCrm(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/commercials/users/" + id + "/activate-crm", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) {
                getModule(SnackbarModule).makeToast("El estado del comercial se ha cambiado con exito")
            }
            // @ts-ignore
            component.refresh()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han cambiar el estado del comercial")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async deactivateMyCommercialCrm(component: Vue, id: number) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.patch(ConstantTool.BASE_URL + "/api/@me/commercials/users/" + id + "/deactivate-crm", null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            if (response.status == 200) {
                getModule(SnackbarModule).makeToast("El estado del comercial se ha cambiado con exito")
            }
            // @ts-ignore
            component.refresh()
        } catch (err) {
            getModule(SnackbarModule).makeToast("No se han cambiar el estado del comercial")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async grantCommercials(id: number): Promise<Response<User>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + `/api/users/${id}/allow-commercials`, null, {
                headers: { Authorization: getModule(SessionModule).session.token }
            })
            const commercialUser = JsonTool.jsonConvert.deserializeObject(response.data, User)
            return Promise.resolve({ result: commercialUser })
        } catch (e) { return Promise.reject(e) }
    }

    static async getMyCommercialsCSV(component: Vue, page: number, size: number, all: boolean = false, active: boolean = true) {
        // @ts-ignore
        component.loading = true
        try {
            const response = await component.axios.get(ConstantTool.BASE_URL + "/api/@me/commercials/csv", {
                params: { page, size, active, all },
                headers: {Authorization: getModule(SessionModule).session.token}
            })
            const blob = new Blob([ response.data ], { "type" : "text/csv" })
            let link = document.createElement('a')
            // @ts-ignore
            link.href = window.URL.createObjectURL(blob)
            link.download = 'commercials.csv'
            // @ts-ignore
            link.click()
        } catch(e) {
            console.log(e)
            getModule(SnackbarModule).makeToast("No se han podido obtener los comerciales.")
        } finally {
            // @ts-ignore
            component.loading = false
        }
    }

    static async patchMe(id: number, request: User): Promise<Response<User>> {
        try {
            const response = await axios.patch(ConstantTool.BASE_URL + "/api/users/@me", request, {
                headers: {Authorization: getModule(SessionModule).session.token}
            })

            const user = JsonTool.jsonConvert.deserializeObject(response.data, User)
            getModule(SnackbarModule).makeToast("El estado del usuario se ha actualizado con exito.")

            return Promise.resolve({ result: user })
        } catch (e) {

            getModule(SnackbarModule).makeToast("Algo ha salido mal mientras se actualizaba el usuario.")
            return Promise.reject(e)

        }
    }

}